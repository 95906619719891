<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Users
      <ManageUsers
        slot="right"
        @refresh="pageChange"
      />
    </hero-bar>
    <section class="section is-main-section">
      <div class="mb-2">
        <Search
          :search-placeholder="searchPlaceholder"
          :initial-value="search"
          @search="searchData"
        />
      </div>
      <b-table
        :checked-rows.sync="checkedRows"
        :checkable="false"
        :paginated="false"
        :data="users"
        default-sort="name"
        striped
        hoverable
      >
        <b-table-column
          v-slot="props"
          cell-class="has-no-head-mobile is-image-cell"
        >
          <div class="image">
            <img
              :src="'https://ui-avatars.com/api/?background=17561f&color=fafafa&name=' + props.row.name"
              class="is-rounded"
            >
          </div>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Name"
          field="name"
          sortable
        >
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Email"
          field="email"
          sortable
        >
          {{ props.row.email }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Role"
          field="role"
          sortable
        >
          {{ props.row.role }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Country"
          field="country"
          sortable
        >
          {{ props.row.iso_code }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Dial Code"
          field="dial_code"
          sortable
        >
          {{ props.row.dial_code }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Phone"
          field="phone"
          sortable
        >
          {{ props.row.phone }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          custom-key="actions"
          cell-class="is-actions-cell"
        >
          <div class="buttons is-right no-wrap">
            <router-link
              :to="{name:'client.edit', params: {id: props.row.id}}"
              class="button is-small is-success"
            >
              <b-icon
                icon="account-arrow-right"
                size="is-small"
              />
            </router-link>
          </div>
        </b-table-column>
        <section
          slot="empty"
          class="section"
        >
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                icon="emoticon-sad"
                size="is-large"
              />
            </p>
            <p>Nothing's here&hellip;</p>
          </div>
        </section>
      </b-table>
      <pagination
        :total="total"
        :per-page="perPage"
        :current-arg="currentArg"
        @page-change="pageChange"
      />
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Pagination from '@/components/dashboard/Pagination'
import Search from '@/components/dashboard/Search'
import ManageUsers from '@/components/dashboard/ManageUsers'

export default {
  name: 'Users',
  components: {
    TitleBar,
    HeroBar,
    Pagination,
    Search,
    ManageUsers
  },
  data () {
    return {
      titleStack: ['Dashboard', 'Users'],
      users: [],
      checkedRows: [],
      total: null,
      perPage: null,
      currentArg: null,
      page: 1,
      search: '',
      searchPlaceholder: 'Search by name, email, phone, or role'
    }
  },
  created () {
    if (this.$route.query.search) {
      this.search = this.$route.query.search
    }
    this.fetchUsers()
  },
  methods: {
    searchData (search) {
      this.$router.push({ query: { search: search } })
      this.search = search
      this.page = 1
      this.fetchUsers()
    },
    pageChange (page) {
      this.page = page
      this.fetchUsers()
    },
    fetchUsers () {
      this.axios.get(`/v1/super-admin/users?paginate=10&page=${this.page}&search=${this.search}`).then(data => {
        this.users = data.data.data
        this.total = data.data.total
        this.perPage = parseInt(data.data.per_page)
        this.currentArg = data.data.current_page
      })
    }
  }
}
</script>

<style scoped>

</style>
