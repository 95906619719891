<template>
  <div v-if="shipment">
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <b-dropdown
        slot="right"
        v-model="currentMenu"
        :scrollable="isScrollable"
        :max-height="maxHeight"
        aria-role="list"
        position="is-bottom-left"
      >
        <template #trigger>
          <b-button
            :label="currentMenu.text"
            type="is-success is-small"
            :icon-left="currentMenu.icon"
            icon-right="menu-down"
          />
        </template>
        <b-dropdown-item
          v-for="(menu, index) in menus"
          :key="index"
          :value="menu"
          aria-role="listitem"
        >
          <div class="media">
            <b-icon
              class="media-left"
              :icon="menu.icon"
            />
            <div class="media-content">
              <h3>{{ menu.text }}</h3>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </hero-bar>

    <section class="section is-main-section">
      <tiles v-if="currentMenu['text'] !== 'Options'">
        <card-component
          :title="'Options'"
          icon="menu"
          class="tile is-child"
        >
          <payment
            v-if="currentMenu['text'] === 'Payments'"
            @refresh="fetch"
          />
          <status
            v-if="currentMenu['text'] === 'Status'"
            :shipment="shipment"
            @refresh="fetch"
          />
          <not-paid
            v-if="currentMenu['text'] === 'Not paid'"
            :shipment="shipment"
            @refresh="fetch"
          />
          <delete-shipment
            v-if="currentMenu['text'] === 'Delete'"
            :shipment="shipment"
          />
          <div v-if="currentMenu['text'] === 'Get QR'">
            <b-button
              type="is-success"
              expanded
              @click="downloadQR()"
            >
              Download QR Document
            </b-button>
          </div>
        </card-component>
      </tiles>
      <tiles>
        <card-component
          icon="menu"
          class="tile is-child"
        >
          <b-field label="Amount Payable (USD)">
            <b-input
              :value="shipment.total_price"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
        <card-component
          icon="menu"
          class="tile is-child"
        >
          <b-field label="Paid Amount (USD)">
            <b-input
              :value="shipment.paid"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
        <card-component
          icon="menu"
          class="tile is-child"
        >
          <b-field label="Balance (USD)">
            <b-input
              :value="calculateBalance(shipment.paid, shipment.total_price)"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
      </tiles>
      <tiles>
        <card-component
          :title="'Shipment Details'"
          icon="clock-outline"
          class="tile is-child"
        >
          <b-field label="Shipment number">
            <b-input
              :value="shipment.tracking_number"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Status">
            <b-input
              :value="shipment.latest_status.status"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Mode">
            <b-input
              :value="shipment.mode"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Transit (State)">
            <b-input
              :value="shipment.transit.name"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Delivery Type">
            <b-input
              :value="shipment.delivery_type"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Delivery Town">
            <b-input
              :value="shipment.delivery_town"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Driver Comment">
            <b-input
              :value="shipment.driver_comment"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Date Created">
            <b-input
              :value="shipment.created_at | formatDateLocale"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
        <card-component
          :title="'Shipment Items'"
          icon="gift"
          class="tile is-child"
        >
          <b-collapse
            v-for="(pack, index) of shipment.shipment_items"
            :key="index"
            class="card"
            animation="slide"
            :open="isOpen == index"
            :aria-id="'contentIdForA11y5-' + index"
            @open="isOpen = index"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                :aria-controls="'contentIdForA11y5-' + index"
                :aria-expanded="props.open"
              >
                <p class="card-header-title">
                  {{ pack.package_type }}
                </p>
                <a class="card-header-icon">
                  <b-icon
                    :icon="props.open ? 'menu-down' : 'menu-up'"
                  />
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <div class="columns is-mobile">
                  <div class="column">
                    <b-field label="Package Content">
                      <b-input
                        :value="pack.package_content"
                        custom-class="is-static"
                        readonly
                      />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Package Dimensions (L/W/H)">
                      <b-input
                        :value="pack.length + ' / ' + pack.width + ' / ' + pack.height"
                        custom-class="is-static"
                        readonly
                      />
                    </b-field>
                  </div>
                </div>
                <div class="columns is-mobile">
                  <div class="column">
                    <b-field label="Quantity">
                      <b-input
                        :value="pack.quantity"
                        custom-class="is-static"
                        readonly
                      />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Package Value ($)">
                      <b-input
                        :value="pack.package_value"
                        custom-class="is-static"
                        readonly
                      />
                    </b-field>
                  </div>
                </div>
                <div class="columns is-mobile">
                  <div class="column">
                    <b-field label="Package Volume">
                      <b-input
                        :value="pack.package_volume"
                        custom-class="is-static"
                        readonly
                      />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Package Content">
                      <b-input
                        :value="pack.package_content"
                        custom-class="is-static"
                        readonly
                      />
                    </b-field>
                  </div>
                </div>
                <div class="columns is-mobile">
                  <div class="column">
                    <b-field label="Delivery Instructions">
                      <b-input
                        :value="pack.delivery_instructions"
                        custom-class="is-static"
                        readonly
                      />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field
                      v-show="pack.driver_comment"
                      label="Driver Comment"
                    >
                      <b-input
                        :value="pack.driver_comment"
                        custom-class="is-static"
                        readonly
                      />
                    </b-field>
                  </div>
                </div>
                <manage-shipment-item
                  :package="pack"
                  @refresh="fetch"
                />
              </div>
            </div>
          </b-collapse>
        </card-component>
      </tiles>
      <tiles>
        <card-component
          :title="'Client Details'"
          icon="account-arrow-right"
          class="tile is-child"
        >
          <b-field label="Name">
            <b-input
              :value="shipment.shipper.name"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Email">
            <b-input
              :value="shipment.shipper.email"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Phone">
            <b-input
              :value="shipment.shipper.dial_code + ' ' + shipment.shipper.phone"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
        <card-component
          :title="'Recipient Details'"
          icon="account-arrow-left"
          class="tile is-child"
        >
          <b-field label="Name">
            <b-input
              :value="shipment.receiver.name"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Email">
            <b-input
              :value="shipment.receiver.email"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Phone">
            <b-input
              :value="shipment.receiver.dial_code + ' ' + shipment.receiver.phone"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Delivery Type">
            <b-input
              :value="shipment.receiver.delivery_type"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
        <card-component
          v-if="shipment.driver != null"
          :title="'Driver Details'"
          icon="car"
          class="tile is-child"
        >
          <b-field label="Name">
            <b-input
              :value="shipment.driver.name"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Email">
            <b-input
              :value="shipment.driver.email"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Phone">
            <b-input
              :value="shipment.driver.dial_code + ' ' + shipment.driver.phone"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles.vue'
import CardComponent from '@/components/CardComponent.vue'
import Payment from '@/components/dashboard/options/Payment'
import Status from '@/components/dashboard/options/Status'
import NotPaid from '@/components/dashboard/options/NotPaid'
import DeleteShipment from '@/components/dashboard/options/DeleteShipment'
import ManageShipmentItem from '@/components/dashboard/shipment/ManageShipmentItem.vue'

export default {
  name: 'Shipment',
  components: {
    ManageShipmentItem,
    TitleBar,
    HeroBar,
    Tiles,
    CardComponent,
    Payment,
    Status,
    NotPaid,
    DeleteShipment
  },
  data () {
    return {
      shipment: null,
      isScrollable: false,
      maxHeight: 200,
      isOpen: null,
      currentMenu: {
        icon: 'menu',
        text: 'Options'
      },
      menus: [
        {
          icon: 'credit-card-multiple',
          text: 'Payments'
        },
        {
          icon: 'playlist-edit',
          text: 'Status'
        },
        {
          icon: 'cancel',
          text: 'Not paid'
        },
        {
          icon: 'delete',
          text: 'Delete'
        }
      ]
    }
  },
  computed: {
    titleStack () {
      return [
        'Dashboard',
        'Shipments',
        this.shipment != null ? this.shipment.tracking_number : 'Shipment'
      ]
    },
    heroTitle () {
      return this.shipment != null ? this.shipment.tracking_number : 'Shipment'
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    downloadQR () {
      window.open('https://linctechapp.com/storage/sticker/' + this.shipment.qr_sticker_name, '_blank')
    },
    fetch () {
      this.axios.get('/v1/app/shipment/' + this.$route.params.id)
        .then(response => {
          this.shipment = response.data
          if (this.shipment.qr_sticker_name) {
            this.menus.push({
              icon: 'qrcode',
              text: 'Get QR'
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
