<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Shipments
    </hero-bar>
    <section class="section is-main-section">
      <div class="columns mb-2">
        <div class="column">
          <Search
            :search-placeholder="searchPlaceholder"
            :initial-value="search"
            @search="searchData"
          />
        </div>
        <div class="column">
          <b-select
            v-model="form.state"
            placeholder="Select a state"
            expanded
            @input="fetch"
          >
            <option value="">
              Select a state
            </option>
            <option
              v-for="option in states"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </div>
        <div class="column">
          <b-select
            v-model="form.mode"
            placeholder="Select mode"
            expanded
            @input="fetch"
          >
            <option value="">
              Select mode
            </option>
            <option
              v-for="option in modes"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </b-select>
        </div>
        <div class="column">
          <b-datepicker
            v-model="form.date"
            placeholder="Date/Start Date"
            icon="calendar-today"
            :max-date="new Date()"
            locale="en-CA"
            :date-formatter="parseDate"
            editable
            :icon-right="form.date ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="clearDate"
            @input="fetch"
          />
        </div>
        <div class="column">
          <b-datepicker
            v-model="form.end"
            placeholder="End Date"
            icon="calendar-today"
            :max-date="new Date()"
            locale="en-CA"
            :date-formatter="parseDate"
            editable
            :icon-right="form.end ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="clearDateEnd"
            @input="fetch"
          />
        </div>
      </div>
      <b-table
        :checked-rows.sync="checkedRows"
        :checkable="false"
        :paginated="false"
        :data="shipments"
        default-sort="name"
        striped
        hoverable
      >
        <b-table-column
          v-slot="props"
          cell-class="has-no-head-mobile is-image-cell"
        >
          <div class="image">
            <img
              :src="'https://ui-avatars.com/api/?background=17561f&color=fafafa&name=' + props.row.mode"
              class="is-rounded"
            >
          </div>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Mode"
          field="mode"
          sortable
        >
          {{ props.row.mode }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Tracking number"
          field="tracking_number"
          sortable
        >
          {{ props.row.tracking_number }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Item(s) count"
          field="items_count"
          sortable
        >
          {{ props.row.shipment_items.length }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Total($)"
          field="total"
          sortable
        >
          {{ props.row.total_price }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Paid($)"
          field="paid"
          sortable
        >
          {{ props.row.paid }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Balance($)"
          field="balance"
          sortable
        >
          {{ calculateBalance(props.row.paid, props.row.total_price) }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Date"
          field="date"
          sortable
        >
          <small style="color: blue">{{ props.row.created_at | formatDateLocale }}</small>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Status"
          field="status"
          sortable
        >
          <b-tag :type="props.row.latest_status.status === 'In Transit' ? 'is-info' : 'is-success'">
            {{ props.row.latest_status.status }}
          </b-tag>
        </b-table-column>
        <b-table-column
          v-slot="props"
          custom-key="actions"
          cell-class="is-actions-cell"
        >
          <div class="buttons is-right no-wrap">
            <router-link
              :to="{name:'shipment', params: {id: props.row.tracking_number}}"
              class="button is-small is-success"
            >
              <b-icon
                icon="arrow-right-circle"
                size="is-small"
              />
            </router-link>
          </div>
        </b-table-column>
        <section
          slot="empty"
          class="section"
        >
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                icon="emoticon-sad"
                size="is-large"
              />
            </p>
            <p>Nothing's here&hellip;</p>
          </div>
        </section>
      </b-table>
      <pagination
        :total="total"
        :per-page="perPage"
        :current-arg="currentArg"
        @page-change="pageChange"
      />
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Pagination from '@/components/dashboard/Pagination'
import Search from '@/components/dashboard/Search'
import moment from 'moment'
import mm from 'moment-timezone'

export default {
  name: 'Shipments',
  components: {
    TitleBar,
    HeroBar,
    Pagination,
    Search
  },
  data () {
    return {
      titleStack: ['Dashboard', 'Shipments'],
      checkedRows: [],
      total: null,
      perPage: null,
      currentArg: null,
      page: 1,
      search: '',
      searchPlaceholder: 'Search by tracking number',
      shipments: [],
      modes: [
        'Ocean Cargo',
        'Air Cargo'
      ],
      states: [],
      form: {
        state: '',
        mode: '',
        date: '',
        end: ''
      }
    }
  },
  created () {
    if (this.$route.query.search) {
      this.search = this.$route.query.search
    }
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    if (this.$route.query.transit) {
      this.form.state = this.$route.query.transit
    }
    if (this.$route.query.mode) {
      this.form.mode = this.$route.query.mode
    }
    if (this.$route.query.date) {
      this.form.date = new Date(this.$route.query.date)
    }
    if (this.$route.query.end) {
      this.form.end = new Date(this.$route.query.end)
    }
    this.fetch()
    this.fetchStates()
  },
  methods: {
    clearDate () {
      this.form.date = ''
      this.fetch()
    },
    clearDateEnd () {
      this.form.end = ''
      this.fetch()
    },
    parseDate (date) {
      if (date) {
        return moment(date).format('YYYY-MM-DD')
      }

      return ''
    },
    fetchStates () {
      this.axios.get('/v1/app/states')
        .then(response => {
          this.states = response.data.states
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetch () {
      this.$router.push({ query: { search: this.search, page: `${this.page}`, transit: this.form.state, mode: `${this.form.mode}`, date: `${this.parseDate(this.form.date)}`, end: `${this.parseDate(this.form.end)}` } })
      this.axios.get(`/v1/app/shipment?paginate=10&tId=${this.search}&page=${this.page}&transit=${this.form.state}&mode=${this.form.mode}&date=${this.parseDate(this.form.date)}&end=${this.parseDate(this.form.end)}&timezone=${mm.tz.guess()}`)
        .then(response => {
          this.shipments = response.data.shipments.data
          this.total = response.data.shipments.total
          this.perPage = parseInt(response.data.shipments.per_page)
          this.currentArg = response.data.shipments.current_page
        })
        .catch(error => {
          console.log(error)
        })
    },
    searchData (search) {
      this.search = search
      this.page = 1
      this.fetch()
    },
    pageChange (page) {
      this.page = page
      this.fetch()
    }
  }
}
</script>

<style scoped>

</style>
